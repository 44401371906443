import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell marró grisenc amb tonalitats violàcies de fins a 7 cm de diàmetre que amb l’edat queda pla i deprimit al centre. Pot presentar un petit mamelló. Es característic la mena de gelatina que cobreix la part superior del capell, especialment en temps humit, que pot cobrir tot el bolet. El peu blanc a la part superior, és curt, un poc corbat i viscós. És un bolet amb làmines amples, separades i decurrents que varien el color de blanc a negre per la caiguda de les espores. Les espores són de color marró a negrós en massa, d’el·líptiques a fusiformes, de 16-22 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      